<template>
  <div class="message-row" :style="{ width: `${width}px` }" :data-is-my-side="isMySide"
    :data-hide-picture="shouldHidePicture()" :data-show-date="showDateHeader" :data-show-footer="showFooter"  :data-highlighted="isHighlighted">
    <div class="message-date-header" v-if="showDateHeader">
      <span>{{ getMessageDate() }}</span>
    </div>
    <img class="message-author" :src="senderPicture" :alt="senderName" v-if="showPicture">
    <img class="bubble-purple" src="@/assets/bubble-purple.png" alt="Bubble" v-if="showFooter && isMySide">
    <img class="bubble-gray" src="@/assets/bubble-gray.png" alt="Bubble" v-if="showFooter && !isMySide">
    <div class="message-content" :style="{ maxWidth: `${getMaxWidth()}px` }">
      <slot></slot>
      <div class="message-actions" ref="messageActions" v-if="showActions">
        <img class="actions-button" src="@/assets/icons/more-vertical.png" alt="Actions Button" @click.prevent="openActionsPopup()">
        <transition mode="out-in" name="modal-pop">
          <MessageActionsPopup v-if="isActionsPopupOpen" :isMySide="isMySide" :senderMethod="senderMethod" @onClickAction="handleClickAction" />
        </transition>
      </div>
    </div>
    <span class="message-footer" v-if="showFooter">
      <span class="message-sender">{{ senderName }}</span>
      <i class="dot"></i>
      <span class="message-time">{{ getMessageTime() }}</span>
      <i class="dot message-method-dot" v-if="senderMethod"></i>
      <span class="message-method" v-if="senderMethod">{{ getMessageMethod() }}</span>
      <img class="secure-icon" src="@/assets/icons/secure.png" v-if="isMessageMethodSecure()">
    </span>
  </div>
</template>

<script>
import * as commonUtils from '../../../../utils/common'
import MessageActionsPopup from './MessageActionsPopup.vue'

const MAX_WIDTH = 600;

export default {
  name: 'MessageRow',
  props: {
    senderName: String,
    senderPicture: String,
    senderMethod: String,
    width: Number,
    dateCreated: String,
    isMySide: Boolean,
    showFooter: Boolean,
    showPicture: Boolean,
    showDateHeader: Boolean,
    showActions: Boolean,
    isHighlighted: Boolean
  },
  data() {
    return {
      isActionsPopupOpen: false
    }
  },
  mounted() {
    window.addEventListener('click', this.onMouseClick);
  },
  unmounted() {
    window.removeEventListener('click', this.onMouseClick);
  },
  methods: {
    openActionsPopup() {
      this.isActionsPopupOpen = true;
    },
    onMouseClick(event) {
      const target = event.target;
      const messageActionsRef = this.$refs.messageActions;
      if (!messageActionsRef) return;
      if (!messageActionsRef.contains(target)) {
        this.isActionsPopupOpen = false;
      }
    },
    handleClickAction(type) {
      this.isActionsPopupOpen = false;
      this.$emit('onClickAction', type);
    },
    getMaxWidth() {
      let targetSize;

      if (this.width < 600) {
        targetSize = this.width - 50;
      } else {
        targetSize = this.width - 2 * 63;
      }

      return Math.min(targetSize, MAX_WIDTH);
    },
    getMessageDate() {
      const date = new Date(this.dateCreated);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

      // Check if the date is today
      if (date.toLocaleDateString() === today.toLocaleDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday";
      }

      // Check if the date is within the last week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      if (today - date < 7 * oneDay) {
        return dayOfWeek;
      }

      // If more than a week ago, return MM/DD/YYYY format
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
    getMessageTime() {
      return commonUtils.getTimeOfDay(this.dateCreated);
    },
    isMessageMethodSecure() {
      switch (this.senderMethod) {
        case 'SMS_INSECURE':
        case 'EMAIL_INSECURE':
          return false;
        case 'SMS_SECURE':
        case 'EMAIL_SECURE':
        case 'PORTAL_SECURE':
          return true;
      }

      return false;
    },
    getMessageMethod() {
      switch (this.senderMethod) {
        case 'SMS_INSECURE':
        case 'SMS_SECURE':
          return 'SMS';
        case 'EMAIL_INSECURE':
        case 'EMAIL_SECURE':
          return 'Email';
      }

      return 'Portal';
    },
    shouldHidePicture() {
      return this.width < 600;
    },
  },
  components: { MessageActionsPopup },
  emits: ['onClickAction']
}
</script>

<style scoped>
.message-row {
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-row[data-show-date="true"] {
  padding-top: 59px;
}

/* Message Date */
.message-date-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.message-date-header span {
  display: block;
  padding: 10px;
  border-radius: 10px;
  background: var(--color-fill-med);
  font-size: 14px;
  color: #999;
}

/* Row itself */

.message-row[data-is-my-side="true"] {
  padding-right: 63px;
  align-items: flex-end;
}

.message-row[data-is-my-side="false"] {
  padding-left: 63px;
  align-items: flex-start
}

.message-row[data-hide-picture="true"] {
  padding-left: 0px;
  padding-right: 0px;
}

/* Author */

.message-author {
  position: absolute;
  bottom: 46.5px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

/* .message-row[data-show-date="true"] .message-author {
  top: 59px;
} */

.message-row[data-is-my-side="false"] .message-author {
  left: 0;
}

.message-row[data-is-my-side="true"] .message-author {
  right: 0;
}

.message-row[data-hide-picture="true"] .message-author {
  display: none;
}

/* Message Items */
.message-content {
  position: relative;
  display: flex;
}

.message-footer {
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-bottom: 20px;
  opacity: 0.6;
}

.dot {
  display: block;
  background: #000;
  width: 3px;
  height: 3px;
  border-radius: 3px;
}

.message-row[data-is-my-side="true"] .message-content {
  flex-direction: row-reverse;
}

.secure-icon {
  height: 16px;
  display: block;
}

@media screen and (max-width: 600px) {
  .message-method {
    display: none;
  }

  .message-method-dot {
    display: none;
  }

  .secure-icon {
    display: none;
  }
}

.message-actions {
  top: 0;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-row[data-is-my-side="true"] .message-actions {
  left: -30px;
}

.message-row[data-is-my-side="false"] .message-actions {
  right: -30px;
}

.actions-button {
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  opacity: 0;
  transition: opacity ease-in-out 0.1s;
}

.message-row:hover .actions-button {
  opacity: 0.2;
}

.actions-button:hover {
  opacity: 1 !important;
}

.bubble-purple {
  position: absolute;
  right: 53px;
  bottom: 46.5px;
  width: 20px;
  z-index: 0;
  pointer-events: none;
}

.message-row[data-hide-picture="true"] .bubble-purple {
  right: -10px;
}

.bubble-gray {
  position: absolute;
  left: 53px;
  bottom: 46.5px;
  width: 20px;
  z-index: 0;
  pointer-events: none;
}

.message-row[data-hide-picture="true"] .bubble-gray {
  left: -10px;
}

.message-row[data-highlighted="true"] .message-content {
  box-shadow: 0px 0px 10px 5px #0055ff55;
  border-radius: 15px;
}
</style>