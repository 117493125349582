<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/account-settings', label: 'Account', hasNotif: false },
      { path: '/user-settings', label: 'Users', hasNotif: false },
    ]" />
    <!-- Sidebar -->
    <div class="panel-v2 settings-sidebar">
      <div class="panel-v2-header">
        <h2 class="heading">Account Settings</h2>
      </div>
      <div class="panel-v2-body">
      </div>
    </div>
    <!-- Scroll list -->
    <transition name="modal-appear" mode="out-in">
      <div class="wrapper" v-if="!loading">
        <PublicProfilePanel />
        <MessagingMethodsPanel />
      </div>
    </transition>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'
import { store } from '../../../store'

import NavigationBar from '@/components/shared/NavigationBar.vue';
import PublicProfilePanel from '@/components/core/settings/PublicProfilePanel.vue'
import MessagingMethodsPanel from '@/components/core/settings/MessagingMethodsPanel.vue';

export default {
  name: 'AccountSettingsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      store,
      loading: false
    }
  },
  created() {
    this.fetchAccountInfo();
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    getFormattedPhone() {
      if (!store.accountInfo.publicPhoneNumber) return 'No phone number'

      let v = store.accountInfo.publicPhoneNumber;
      v = v.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        return `(${v.substring(0, 3)}`
      }
    },
    getFormattedEmail() {
      if (!store.accountInfo.publicPhoneNumber) return 'No email address'
      let v = store.accountInfo.publicEmailAddress;
      return v;
    },
    async fetchAccountInfo() {
      try {
        this.loading = true;
        const accountInfo = await coreApi.fetchAccountInfo();
        store.accountInfo = accountInfo;
      } catch (err) {
        console.error(err.message);
      } finally {
        this.loading = false;
      }
    }
  },
  components: { NavigationBar, PublicProfilePanel, MessagingMethodsPanel }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.settings-sidebar {
  flex-grow: 0;
  flex-basis: 400px;
}

.wrapper {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.wrapper .panel-v2 {
  flex: unset
}

td {
  padding-bottom: 20px;
}

.info {
  margin-left: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-txt-light);
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;
}

table {
  width: 100%;
  table-layout: fixed;
}

select {
  font-size: 18px;
}
</style>