import { postJson } from './common.js'

export async function createForm(name) {
  const resJson = await postJson('/forms/form/create', { name });
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function listForms() {
  const resJson = await postJson('/forms/form/list');
  if (!resJson.forms) throw Error('Malformed response from backend');
  return resJson.forms;
}

export async function fetchFormInfo(formId) {
  const resJson = await postJson('/forms/form/info', {id: formId});
  if (!resJson.form) throw Error('Malformed response from backend');
  return resJson.form;
}

export async function updateForm(formId, sections, logoBase64) {
  await postJson('/forms/form/update', {id: formId, sections, logoBase64});
}

export async function sendSubmission(patientId, formIds) {
  const resJson = await postJson('/forms/submission/send', { patientId, formIds });
  if (!resJson.submission) throw Error('Malformed response from backend');
  return resJson.submission;
}

export async function listSubmissions() {
  const resJson = await postJson('/forms/submission/list');
  if (!resJson.submissions) throw Error('Malformed response from backend');
  return resJson.submissions;
}
