<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Send Forms</h1>
      <p class="subtitle">What forms should we send to {{ patient.firstName }} {{ patient.lastName }}?</p>
    </div>
    <div class="modal-card-body">
      <div class="forms-list">
        <div class="form-list-item" v-for="form of store.accountForms" :key="form.id"
          @click.prevent.stop="toggleForm(form)">
          <CheckBox size="25px" thickness="3px" :isChecked="isFormSelected(form)" />
          <img src="@/assets/icons/form.png" alt="Form" class="form-icon">
          <span>
            <strong>{{ form.name }}</strong>
            <span style="margin-left: 10px" v-if="isFormSelected(form)">(#{{ getFormIndex(form) + 1 }})</span>
          </span>
        </div>
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" :disabled="selected.length == 0" @click.prevent="attemptSendForms">
        <img v-if="pendingSend" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Send {{ selected.length }} Forms</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { store } from '../../store'
import * as formsApi from '../../api/forms.js'
import CheckBox from '../../components/shared/CheckBox.vue';

export default {
  components: { CheckBox },
  name: "SendFormsModal",
  props: {
    patient: Object
  },
  data() {
    return {
      store,
      selected: [],
      pendingSend: false,
      error: ''
    }
  },
  methods: {
    async attemptSendForms() {
      if (this.pendingSend) return;

      if (this.selected.length == 0) {
        this.error = 'You must select at least one form';
        return;
      }

      try {
        this.error = '';
        this.pendingSend = true;
        const submission = await formsApi.sendSubmission(this.patient.id, this.selected);
        this.$emit('onSubmissionCreated', submission);
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.pendingSend = false;
      }
    },
    isFormSelected(form) {
      return this.selected.some(id => id == form.id);
    },
    toggleForm(form) {
      if (this.isFormSelected(form)) {
        this.selected = this.selected.filter(id => id != form.id);
      } else {
        this.selected.push(form.id);
      }
    },
    getFormIndex(form) {
      return this.selected.findIndex(id => id == form.id);
    }
  },
  emits: ['onSubmissionCreated']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.forms-list {
  max-height: 300px;
  overflow-y: auto;
}

.form-list-item {
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.form-list-item:hover {
  background: var(--color-fill-med);
}

.form-icon {
  margin-left: 10px;
  height: 35px;
  display: block;
}
</style>