<template>
  <div class="chart-field-select">
    <input type="text" v-model="text" @focus="onFocus" @blur="onBlur" ref="input" :placeholder="placeholder" autocomplete="off"
      data-lpignore="true" data-form-type="other">
    <img src="@/assets/icons/link.png" alt="Link Icon" class="link-icon" v-if="isLinked()">
    <div class="suggestion-box">
      <div class="suggestion-list">
        <div class="suggestion" @mousedown.prevent.stop="onSelectCustom()">
          <span class="category-tag">Not Imported</span>
          <i>Custom Field</i>
        </div>
        <div class="suggestion" v-for="field of getFields()" :key="field.path"
          @mousedown.prevent.stop="onSelectField(field)" :data-selected="field.selected" ref="suggestions">
          <img src="@/assets/icons/link.png" alt="Link Icon" class="link-icon">
          <span class="category-tag">{{ field.category }}</span>
          <span>{{ field.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formSchema from '../../assets/formschema.json'

export default {
  name: 'ChartFieldSelect',
  props: {
    value: String,
    placeholder: String
  },
  data() {
    return {
      text: '',
      formSchema,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.text = this.getLabelForPath(newValue)
      },
      immediate: true
    }
  },
  methods: {
    getLabelForPath(path) {
      if (!path) {
        return '';
      }

      if (path.startsWith('custom')) {
        return 'Custom Field'
      }

      const field = this.getFields().find(field => field.path == path);
      if (field) {
        return field.label;
      }

      return 'Unknown field'
    },
    getFields() {
      const result = [];

      const query = this.text
        .toLowerCase()
        .replace(/\s/g, '');

      for (let category of formSchema) {
        for (let field of category.fields) {
          if (field.path.toLowerCase().includes(query)) {
            result.push({
              category: category.label,
              selected: field.path == this.value,
              ...field
            });
          }
        }
      }

      return result;
    },
    onFocus() {
      this.text = "";
    },
    onBlur() {
      this.text = this.getLabelForPath(this.value);
    },
    onSelectField(field) {
      this.$emit('onSelectField', field);

      this.$nextTick(() => {
        const inputRef = this.$refs.input;
        if (inputRef) inputRef.blur();
      })
    },
    onSelectCustom() {
      const randomPath = 'custom.field' + Math.random();
      this.$emit('onSelectField', { path: randomPath, label: '' });

      this.$nextTick(() => {
        const inputRef = this.$refs.input;
        if (inputRef) inputRef.blur();
      })
    },
    isLinked() {
      return this.value && !this.value.startsWith('custom')
    }
  },
  emits: ['onSelectField']
}
</script>

<style scoped>
.chart-field-select {
  position: relative;
}

input {
  border: none;
  padding: 10px;
  padding-left: 40px;
  font-size: 16px;
  width: 100%;
  background: transparent;
}

.suggestion-box {
  position: absolute;
  width: 300px;
  max-height: 250px;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: #FFF;
  box-shadow: 0px 0px 12px #00000022;
  overflow-y: auto;
  border-radius: 10px;
}

input:not(:focus)~.suggestion-box {
  display: none;
}

.category-header {
  padding: 10px;
  font-weight: bold;
}

.category-tag {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
}

.suggestion {
  position: relative;
  padding: 10px;
  padding-left: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.suggestion:hover {
  background: #F2F2F2;
  color: var(--color-primary);
}

.suggestion[data-selected="true"] {
  color: var(--color-primary);
}

.link-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  display: block;
}
</style>