<template>
  <div class="send-button-container">
    <button class="button primary small send-button" @click.prevent="togglePopup" :data-hidden="page.length > 0">
      <img v-if="isSendingMessage" src="/loading-white.svg" class="loading" alt="Loading Icon">
      <img v-else src="@/assets/icons/right-arrow.png" class="right-arrow" alt="Right Arrow">
    </button>
    <transition name="modal-pop" mode="out-in">
      <div class="send-popup" ref="popup" v-if="page">
        <transition name="modal-slide" mode="out-in">
          <div class="popup-page" v-if="page == 'select-security-level'">
            <button class="button primary popup-button" @click="openPage('select-secure-method')">
              Send Secure Message
              <img src="@/assets/icons/right-arrow.png" alt="Right Arrow">
            </button>
            <button class="button primary popup-button" @click="openPage('select-insecure-method')">
              Send Regular Message
              <img src="@/assets/icons/right-arrow.png" alt="Right Arrow">
            </button>
          </div>
          <div class="popup-page" v-else-if="page == 'select-secure-method'">
            <button class="button primary popup-button" @click="sendMessage('SMS_SECURE')" :disabled="!isMethodEnabled('SMS_SECURE')">
              <img src="@/assets/icons/secure.png" alt="Secure Icon">
              Send via secure SMS
            </button>
            <button class="button primary popup-button" @click="sendMessage('EMAIL_SECURE')" :disabled="!isMethodEnabled('EMAIL_SECURE')">
              <img src="@/assets/icons/secure.png" alt="Secure Icon">
              Send via secure Email
            </button>
          </div>
          <div class="popup-page" v-else-if="page == 'select-insecure-method'">
            <button class="button primary popup-button" @click="sendMessage('SMS_INSECURE')" :disabled="!isMethodEnabled('SMS_INSECURE')">
              <img src="@/assets/icons/sms.png" alt="SMS Icon">
              Send via SMS
             </button>
            <button class="button primary popup-button" @click="sendMessage('EMAIL_INSECURE')" :disabled="!isMethodEnabled('EMAIL_INSECURE')">
              <img src="@/assets/icons/email.png" alt="Email Icon">
              Send via Email
            </button>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { store } from '../../../store'

export default {
  name: 'PatientChatSendButton',
  props: {
    patient: Object,
    isSendingMessage: Boolean
  },
  data() {
    return {
      page: ''
    }
  },
  watch: {
    page() {
      this.$nextTick(() => {
        const popupRef = this.$refs.popup;
        if (!popupRef) return;
        popupRef.style.top = `-${popupRef.offsetHeight}px`;
      });
    },
  },
  methods: {
    togglePopup() {
      if (this.page) {
        this.page = '';
      } else {

        if (!this.isInsecureEnabled()) {
          this.page = 'select-secure-method'
        } else {
          this.page = 'select-security-level'
        }
      }
    },
    openPage(page) {
      this.page = page;
    },
    isInsecureEnabled() {
      const allowedMethods = store.accountInfo.allowedMessageMethods;

      if (!allowedMethods) {
        return false;
      }

      if (!allowedMethods.includes("SMS_INSECURE") && !allowedMethods.includes("EMAIL_INSECURE")) {
        return false;
      }

      return true;
    },
    isMethodEnabled(method) {
      const allowedMethods = store.accountInfo.allowedMessageMethods;

      if (!allowedMethods) {
        return false;
      }

      if (!allowedMethods.includes(method)) {
        return false;
      }

      switch (method) {
        case 'SMS_INSECURE':
        case 'SMS_SECURE':
          return this.patient.mobilePhoneNumber != null;
        case 'EMAIL_INSECURE':
        case 'EMAIL_SECURE':
          return this.patient.emailAddress != null;
      }

      return false;
    },
    sendMessage(method) {
      this.$emit('onSendMessage', method);
      this.page = '';
    }
  },
  emits: ['onSendMessage']
}
</script>

<style scoped>
.send-button-container {
  position: relative;
}

.send-button {
  height: 44px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: opacity ease-in-out 0.2s;
}

.send-button[data-hidden="true"] {
  opacity: 0.5;
}

.send-popup {
  position: absolute;
  right: 0;
  padding-bottom: 10px;
  background: #FFFFFFAA;
  box-shadow: 0px 0px 1000px 1000px #FFFFFFAA;
}

.popup-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.popup-button {
  text-wrap: nowrap;
  padding: 15px;
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 16px;
}

.popup-button img {
  height: 18px;
  display: block;
}

.popup-button:not(:disabled) img {
  filter: invert(1);
}

.send-button .right-arrow {
  height: 18px;
  display: block;
  filter: invert(1);
}
</style>