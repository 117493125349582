<template>
  <div class="panel-v2 chat-list-sidebar" :data-collapsed="hasPatientSelected()">
    <div class="panel-v2-header">
      <slot></slot>
    </div>
    <div class="panel-v2-body">
      <!-- <div class="search-bar">
        <img src="@/assets/icons/search.png" alt="Search Icon" class="search-bar-icon">
        <input type="text" class="form-control" placeholder="Search recent chats..." autocomplete="off"
          data-lpignore="true" data-form-type="other" v-model="query" @input="handleQueryInput" ref="search" />
      </div> -->
      <div class="chat-list-items" @scroll="handleScrollEvent">
        <router-link v-for="chat of chats" :key="chat.id" :to="chat.link">
          <div class="chat-list-item" :data-active="isChatActive(chat)">
            <div class="chat-icons" :style="{width: `${getIconsWidth(chat)}px`}">
              <img class="chat-icon" :style="{left: `${idx * 15}px`}" :src="icon.src" :alt="icon.alt" v-for="(icon, idx) of getIconsToShow(chat)" :key="idx">
            </div>
            <div class="chat-description">
              <span class="chat-name">{{ chat.name }}</span>
              <div class="chat-time"> {{ chat.time }}</div>
            </div>
            <div class="indicator-icon" v-if="!chat.isResolved"></div>
          </div>
        </router-link>
        <div class="loading-status" v-if="!doneLoadingChats">
          <img class="loading-icon" src="/loading.svg" v-if="isLoadingChats">
        </div>
        <p v-if="doneLoadingChats && chats.length == 0">{{ emptyText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
const MAX_ICONS = 3;

export default {
  name: 'ChatListSidebar',
  props: {
    chats: Array,
    isLoadingChats: Boolean,
    doneLoadingChats: Boolean,
    emptyText: String
  },
  methods: {
    isChatActive(chat) {
      return this.$route.path == chat.link;
    },
    getIconsToShow(chat) {
      const icons = chat.icons;
      return icons.slice(0, MAX_ICONS);
    },
    getIconsWidth(chat) {
      return Math.min(chat.icons.length, MAX_ICONS) * 15 + 35;
    },
    handleScrollEvent(event) {
      if (this.doneLoadingChats) {
        return;
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.$emit('onLoadMoreChats')
      }
    },
    hasPatientSelected() {
      const params = this.$route.params;
      if (params && params.id) {
        return true;
      }

      return false;
    },
  },
  emits: ['onLoadMoreChats']
}
</script>

<style scoped>
.panel-v2 {
  width: 400px;
  flex-grow: 0;
}

.search-bar {
  margin-bottom: 20px;
}

.chat-list-items {
  overflow-y: auto;
}

.chat-list-items a {
  text-decoration: none;
  color: unset;
}

.chat-list-item {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
}

.chat-list-item[data-active="true"] {
  background: var(--color-fill-med);
}

.chat-icons {
  position: relative;
  height: 50px;
}

.chat-icon {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: -1px 0px 1px #00000022;
}

.chat-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-name {
  font-weight: bold;
}

.chat-time {
  color: #666;
}

.indicator-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -5px;
  z-index: 100;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .panel-v2 {
    position: absolute;
    left: 10px;
    top: calc(80px + env(safe-area-inset-top));
    z-index: 100;
    height: calc(100% - 90px - env(safe-area-inset-top));
    width: calc(100% - 20px);
    transition: all ease-in-out 0.2s;
  }

  .panel-v2[data-collapsed="true"] {
    opacity: 0;
    transform: translateX(-100%);
  }
}

</style>