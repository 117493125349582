<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Patient Profile</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptCreatePatient">
        <div class="form-group">
          <label class="form-label" for="first-name">First Name <span class="required">(required)</span></label>
          <input type="text" id="first-name" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="firstName">
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">Last Name <span class="required">(required)</span></label>
          <input type="text" id="last-name" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="lastName">
        </div>
        <div class="form-group">
          <label class="form-label" for="date-of-birth">Date of Birth <span class="required">(required)</span></label>
          <input type="text" id="date-of-birth" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="MM/DD/YYYY" v-model="dateOfBirth" @input="formatDateOfBirth">
        </div>
        <div class="form-group">
          <label class="form-label" for="phone-number">Phone Number</label>
          <input type="text" id="phone-number" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="(123) 123-1234" v-model="phoneNumber" @input="formatPhoneNumber" :disabled="setPhoneNumber">
        </div>
        <div class="form-group">
          <label class="form-label" for="email-address">Email Address</label>
          <input type="text" id="email-address" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="example@email.com" v-model="emailAddress" :disabled="setEmailAddress">
        </div>
        <input type="submit" value="" style="display: none">
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptCreatePatient">
        <img v-if="pendingCreate" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Create Patient</span>
      </button>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../api/core.js'

export default {
  name: 'PatientCreateModal',
  props: {
    setEmailAddress: String,
    setPhoneNumber: String
  },
  data() {
    return {
      // Create patient
      pendingCreate: false,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: this.setPhoneNumber || '',
      emailAddress: this.setEmailAddress || '',
      error: ''
    };
  },
  mounted() {
    this.formatPhoneNumber();
  },
  methods: {
    async attemptCreatePatient() {

      if (this.pendingCreate) return;

      if (this.firstName.length < 1) {
        this.error = 'First name is a required field';
        return;
      }

      if (this.lastName.length < 1) {
        this.error = 'Last name is a required field';
        return;
      }

      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(this.dateOfBirth)) {
        this.error = 'Date of birth is not valid';
        return;
      }

      try {
        this.error = '';
        this.pendingCreate = true;

        const payload = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth
        }

        if (this.phoneNumber) {
          payload.mobilePhoneNumber = this.phoneNumber.replace(/[^0-9]/g, '');
        }

        if (this.emailAddress) {
          payload.emailAddress = this.emailAddress.toLowerCase();
        }

        const patient = await coreApi.createPatient(payload);
        this.$emit('onCreatePatient', patient)
      } catch (err) {
        this.error = err.message;
      } finally {
        this.pendingCreate = false;
      }
    },
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    },
    formatPhoneNumber() {
      let v = this.phoneNumber;
      v = v.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        this.phoneNumber = `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        this.phoneNumber = `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        this.phoneNumber = `(${v.substring(0, 3)}`
      }
    },
  },
  emits: ['onCreatePatient']
}
</script>

<style scoped>
.modal-card {
  width: 500px;
}

.required {
  opacity: 0.5;
}

</style>