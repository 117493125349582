<template>
  <div class="message-actions-popup" ref="self" :data-my-side="isMySide">
    <span class="action-button" @click="$emit('onClickAction', 'forward-message')">Forward Message</span>
    <span class="action-button" @click="$emit('onClickAction', 'unsend-message')" v-if="canUnsendMessage()">Unsend Message</span>
  </div>
</template>

<script>
export default {
  name: 'MessageActionsPopup',
  props: {
    isMySide: Boolean,
    senderMethod: String
  },
  mounted() {
    this.repositionSelf();
  },
  methods: {
    repositionSelf() {
      const selfRef = this.$refs.self;
      if (!selfRef) return;

      // Center vertically with message
      const parentEl = selfRef.parentElement;
      selfRef.style.top = `${(parentEl.offsetHeight - selfRef.offsetHeight)/2}px`

      // Make sure it fits
      const bb = selfRef.getBoundingClientRect();
      const minX = 20;
      const maxX = window.innerWidth - 20;
      
      if (this.isMySide && bb.left < minX) {
        selfRef.style.right = `${bb.left - minX}px` 
      }

      if (!this.isMySide && bb.right > maxX) {
        selfRef.style.left = `${maxX - bb.right}px`
      }
      console.log(bb);
    },
    canUnsendMessage() {
      if (!this.isMySide) {
        return false;
      }

      if (this.senderMethod == 'SMS_INSECURE') {
        return false;
      }

      if (this.senderMethod == 'EMAIL_INSECURE') {
        return false;
      }

      return true;
    }
  },
  emits: ['onClickAction']
}
</script>

<style scoped>
.message-actions-popup {
  position: absolute;
  background: #FFF;
  box-shadow: 0px 0px 12px #00000022;
  border-radius: 10px;
  z-index: 1005;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0;
}

.message-actions-popup[data-my-side="true"] {
  right: 0;
}

.message-actions-popup[data-my-side="false"] {
  left: 0;
}

.action-button {
  display: block;
  padding: 15px;
  text-wrap: nowrap;
  cursor: pointer;
}

.action-button:hover {
  background: var(--color-fill-med);
}
</style>