<template>
  <div class="insert-section-bar" ref="bar" @mousedown.prevent="isPopupOpen = true">
    <img src="@/assets/icons/add.png" alt="Add Section" class="insert-section-icon">
    <transition name="modal-pop" mode="out-in">
      <div class="section-types" v-if="isPopupOpen">
        <div class="section-type" @click.prevent.stop="insertSection('INFO')">
          <img src="@/assets/icons/info.png" alt="Info Section">
          <span>Information</span>
        </div>
        <div class="section-type" @click.prevent.stop="insertSection('QUESTIONS')">
          <img src="@/assets/icons/textboxes.png" alt="Question Section">
          <span>Questions</span>
        </div>
        <div class="section-type" @click.prevent.stop="insertSection('SIGNATURE')">
          <img src="@/assets/icons/signature.png" alt="Signature Section">
          <span>Signature</span>
        </div>
        <div class="section-type" @click.prevent.stop="insertSection('CHECKLIST')">
          <img src="@/assets/icons/checklist.png" alt="Checklist Section">
          <span>Checklist</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InsertSectionRow',
  data() {
    return {
      isPopupOpen: false
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.onMouseClick);
  },
  unmounted() {
    window.removeEventListener('mousedown', this.onMouseClick);
  },
  methods: {
    insertSection(sectionType) {
      this.isPopupOpen = false;
      this.$emit('insertSection', sectionType);
    },
    onMouseClick(event) {
      const barRef = this.$refs.bar;
      if (!barRef) return;

      if (!barRef.contains(event.target)) {
        this.isPopupOpen = false;
      }
    }
  },
  emits: ['insertSection']
}
</script>

<style scoped>
.insert-section-bar {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.insert-section-icon {
  height: 16px;
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0.9);
  transition: all ease-in-out 0.2s;
}

.insert-section-bar:hover .insert-section-icon {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.section-types {
  z-index: 53;
  position: absolute;
  top: -50px;
  left: calc(50% + -75px);
  background: #FFF;
  box-shadow: 0px 0px 12px #00000022;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

.section-type {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
}

.section-type:hover {
  background: var(--color-fill-med);
}

.section-type img {
  height: 18px;
}
</style>