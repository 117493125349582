import { postJson, postForm } from './common.js'

// Account

export async function createAccount(name, masterEmail, masterPassword) {
  const resJson = await postJson('/core/account/create', { name, masterEmail, masterPassword });
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function fetchAccountInfo() {
  const resJson = await postJson('/core/account/info');
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function updateAccountSettings(payload) {
  const resJson = await postJson('/core/account/update-settings', payload);
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function emrToPatient(emrIntegrationId) {
  const resJson = await postJson('/core/patient/emr-to-patient', { emrIntegrationId });
  return resJson;
}

// Users

export async function userCheckAuth() {
  await postJson('/core/user/check-auth');
}

export async function listAllUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function updateUserSettings(payload) {
  const resJson = await postJson('/core/user/update-settings', payload);
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function uploadUserProfilePicture(userId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('userId', userId);

  const resJson = await postForm('/core/user/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

export async function listInvitations() {
  const resJson = await postJson('/core/userinvitation/list');
  if (!resJson.invitations) throw Error('Malformed response from backend');
  return resJson.invitations;
}

export async function inviteUser(name, email, practiceRole) {
  const resJson = await postJson('/core/userinvitation/create', { name, email, practiceRole });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function revokeInvite(invitationId) {
  await postJson('/core/userinvitation/revoke', { invitationId });
}

export async function useOneTimeCode(oneTimeCode) {
  await postJson('/core/user/use-otc', { oneTimeCode });
}

// Patient

export async function createPatient({ firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId }) {
  const resJson = await postJson('/core/patient/create', { firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function updatePatient(patientId, fieldsToUpdate) {
  const resJson = await postJson('/core/patient/update', { patientId, ...fieldsToUpdate });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function searchPatients(query, afterPatientId) {
  const resJson = await postJson('/core/patient/search', { query, afterPatientId });
  if (!resJson.patients) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchPatientInfo(patientId) {
  const resJson = await postJson('/core/patient/info', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function mobileOptInPatient(patientId) {
  const resJson = await postJson('/core/patient/mobile-opt-in', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function emailOptInPatient(patientId) {
  const resJson = await postJson('/core/patient/email-opt-in', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}
