<template>
  <transition name="modal-pop" mode="out-in">
    <div class="modal-card" v-if="!isNamingGroup">
      <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
      <div class="modal-card-header">
        <h1 class="title">Select Users</h1>
        <p class="subtitle">Select team members that you want to add to this chat. You will be able to invite members
          later too.</p>
      </div>
      <div class="modal-card-body">
        <div class="user-list-item" v-for="user of store.accountUsers" :key="user.id" @click="onToggleUser(user.id)">
          <CheckBox size="25px" thickness="3px" :isChecked="isUserSelected(user.id)" />
          <img :src="getProfilePicture(user)" alt="User Picture" class="user-picture">
          <span class="user-name">{{ user.name }}</span>
          <span class="user-role">{{ user.practiceRole }}</span>
          <div class="spacer" style="flex: 1"></div>
        </div>
      </div>
      <div class="modal-card-footer">
        <button class="button primary" @click.prevent="isNamingGroup = true">Select {{ selected.size }} Users</button>
      </div>
    </div>
    <div class="modal-card" v-else>
      <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
      <div class="modal-card-header">
        <h1 class="title">Create Group Chat</h1>
        <p class="subtitle">What should we call this group chat? Pick something descriptive so you can recognize it on your list.</p>
      </div>
      <div class="modal-card-body">
        <form class="form" style="gap: 0" @submit.prevent="attemptCreateChat">
          <div class="user-icons">
            <img class="user-picture" :src="getProfilePicture(user)" :title="user.name" :alt="user.name"
              v-for="user of getSelectedUsers()" :key="user.id">
          </div>
          <div class="form-group">
            <label class="form-label">Chat Name</label>
            <input type="text" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
              v-model="chatName">
          </div>
          <input type="submit" value="" hidden>
        </form>
      </div>
      <div class="modal-card-footer">
        <button class="button primary" @click.prevent="attemptCreateChat">
          <img v-if="pendingCreate" src="/loading-white.svg" alt="Loading" class="loading">
          <span v-else>Create Group Chat</span>
        </button>
        <p class="error" v-if="error">{{error}}</p>
      </div>
    </div>
  </transition>
</template>

<script>
import { store } from '../../../store'
import * as coreApi from '../../../api/core'
import * as messagingApi from '../../../api/messaging'
import * as commonUtils from '../../../utils/common'
import CheckBox from '../../../components/shared/CheckBox.vue';


export default {
  name: "CreateGroupChatModal",
  data() {
    return {
      store,
      isNamingGroup: false,
      selected: new Set(),
      chatName: '',
      pendingCreate: false,
      error: ''
    }
  },
  mounted() {
    this.attemptFetchUsers();

    // Always add themselves
    if (this.userInfo) {
      this.selected.add(this.userInfo.id);
    }
  },
  methods: {
    async attemptFetchUsers() {
      try {
        const users = await coreApi.listAllUsers();
        store.accountUsers = users;
      } catch (err) {
        console.error('Error', err);
      }
    },
    async attemptCreateChat() {

      if (this.pendingCreate) return;

      try {
        this.error = '';
        this.pendingCreate = true;

        const groupChat = await messagingApi.createGroupChat(this.chatName, Array.from(this.selected));
        this.$emit('onChatCreated', groupChat);    

      } catch (err) {
        console.error('Error', err);
        this.error = err.message;
      }
    },
    onToggleUser(userId) {
      if (this.userInfo && this.userInfo.id == userId) {
        return;
      }

      if (this.selected.has(userId)) {
        this.selected.delete(userId);
      } else {
        this.selected.add(userId);
      }
    },
    isUserSelected(userId) {
      return this.selected.has(userId)
    },
    getSelectedUsers() {
      return store.accountUsers.filter(user => this.isUserSelected(user.id));
    },
    getProfilePicture(user) {
      return commonUtils.getUserPicture(user);
    }
  },
  components: { CheckBox },
  emits: ['openModal', 'closeModal', 'onChatCreated']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
}

.user-list-item:hover {
  background: var(--color-fill-med);
}

.user-picture {
  width: 50px;
  height: 50px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: var(--color-txt-med);
}

.user-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>