<template>
  <div class="panel-v2">
    <div class="panel-v2-header">
      <h2 class="heading">Messaging</h2>
      <button class="button small primary" @click="handleButtonClick">
        <img v-if="pending" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else-if="!isEditing">Edit</span>
        <span v-else>Save</span>
      </button>
    </div>
    <div class="panel-v2-body">
      <div class="setting-line-item">
        <label class="setting-label">Secure SMS</label>
        <div class="view-value" v-if="!isEditing">{{ getStatusForMethod('SMS_SECURE') }}</div>
        <div class="edit-value" v-else>
          <select :value="isMethodEnabled('SMS_SECURE')" @change="handleChangeValue('SMS_SECURE', $event)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Secure Email</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountInfo.publicPhoneNumber">{{ getStatusForMethod('EMAIL_SECURE') }}</span>
          <i v-else>Not connected</i>
        </div>
        <div class="edit-value" v-else>
          <select :value="isMethodEnabled('EMAIL_SECURE')" @change="handleChangeValue('EMAIL_SECURE', $event)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>
      <div class="setting-line-item">
        <label class="setting-label">Insecure SMS</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountInfo.publicPhoneNumber">{{ getStatusForMethod('SMS_INSECURE') }}</span>
          <i v-else>Not connected</i>
        </div>
        <div class="edit-value" v-else>
          <select :value="isMethodEnabled('SMS_INSECURE')" @change="handleChangeValue('SMS_INSECURE', $event)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div>
      <!-- <div class="setting-line-item">
        <label class="setting-label">Insecure Email</label>
        <div class="view-value" v-if="!isEditing">
          <span v-if="store.accountInfo.publicPhoneNumber">{{ getStatusForMethod('EMAIL_INSECURE') }}</span>
          <i v-else>Not connected</i>
        </div>
        <div class="edit-value" v-else>
          <select :value="isMethodEnabled('EMAIL_INSECURE')" @change="handleChangeValue('EMAIL_INSECURE', $event)">
            <option :value="true">Enabled</option>
            <option :value="false">Disabled</option>
          </select>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../../api/core'
import { store } from '../../../store'

const FAKE_LATENCY = 500;

export default {
  name: 'PublicProfilePanel',
  data() {
    return {
      store,
      isEditing: false,
      pending: false,
      error: '',
      allowedMessageMethods: store.accountInfo.allowedMessageMethods
    }
  },
  methods: {
    async handleButtonClick() {
      if (this.pending) return;

      if (this.isEditing) {

        try {
          this.error = '';
          this.pending = true;

          await new Promise((resolve) => {
            setTimeout(resolve, FAKE_LATENCY)
          })

          const account = await coreApi.updateAccountSettings({
            allowedMessageMethods: this.allowedMessageMethods
          })

          store.accountInfo = account;
        } catch (err) {
          this.error = err.message;
          return;
        } finally {
          this.pending = false;
        }
      }

      this.isEditing = !this.isEditing;
    },
    handleChangeValue(method, event) {
      const value = event.target.value;

      this.allowedMessageMethods = this.allowedMessageMethods.filter(meth => {
        return meth != method;
      })

      if (value == "true") {
        this.allowedMessageMethods.push(method);
      }

      this.allowedMessageMethods.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })

      console.log(this.allowedMessageMethods);
    },
    isMethodEnabled(method) {
      return this.allowedMessageMethods.includes(method)
    },
    getStatusForMethod(method) {
      if (this.isMethodEnabled(method)) {
        return 'Enabled';
      }

      return '-';
    }
  }
}
</script>

<style scoped>
.panel-v2-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.setting-line-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.setting-line-item>* {
  flex: 1;
}

label {
  color: var(--color-txt-light);
}

input,
select {
  border: none;
  border: 1px solid var(--color-border-med);
  font-size: 16px;
  padding: 10px;
}

.loading {
  height: 19px;
}
</style>