<template>
  <transition name="modal-pop" mode="out-in">
    <div class="modal-card" v-if="!isCreatingPatient">
      <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
      <div class="modal-card-header" style="margin-bottom: 30px">
        <h1 class="title">Select Patient</h1>
        <p>Please select a patient from your DashQuill patient list.</p>
      </div>
      <div class="modal-card-body">
        <div class="search-bar">
          <img src="@/assets/icons/search.png" alt="Search Icon" class="search-bar-icon">
          <input type="text" class="form-control" placeholder="Search patients..." autocomplete="off"
            data-lpignore="true" data-form-type="other" v-model="query" @input="handleQueryInput" ref="search" />
        </div>

        <div class="patient-list" ref="list" @scroll="handleScroll">
          <div class="patient-list-item" v-for="patient of patients" :key="patient.id"
            @click.prevent="$emit('onSelectPatient', patient)">
            <img :src="getPatientPicture(patient)" alt="Profile Picture" class="patient-picture">
            <div class="patient-description">
              <span class="patient-name">{{ getPatientName(patient) }}</span>
              <span class="patient-phone">{{ getPatientPhone(patient) }}</span>
              <span class="patient-email">{{ getPatientEmail(patient) }}</span>
            </div>
          </div>
          <div class="patient-list-item" v-if="doneLoadingPatients" @click="isCreatingPatient = true">
            <img src="@/assets/icons/add.png" alt="Create Patient" class="add-new-patient">
            <div class="patient-description">
              <span>Add new patient</span>
            </div>
          </div>
          <div class="loading-status" v-if="!doneLoadingPatients">
            <img src="/loading.svg" class="loading-icon" v-if="loading">
          </div>
        </div>
      </div>
    </div>
    <PatientCreateModal v-else @onCreatePatient="$emit('onSelectPatient', $event)" />
  </transition>

</template>

<script>
import PatientCreateModal from './PatientCreateModal.vue';
import * as coreApi from '../../api/core.js'

const QUERY_DELAY = 500;
const FAKE_LATENCY = 500;

export default {
  name: 'PatientSelectModal',
  props: {
    mode: String
  },
  data() {
    return {
      isCreatingPatient: false,

      // Find patient
      query: "",
      timeout: null,
      loading: false,
      error: "",
      patients: [],
      doneLoadingPatients: false,
    };
  },
  mounted() {
    this.loadLatestPatients();
  },
  methods: {
    async loadLatestPatients() {
      try {
        this.loading = true;
        const { patients, isLast } = await coreApi.searchPatients(this.query);
        this.patients = patients;
        this.doneLoadingPatients = isLast;
      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    async loadNextPatients() {
      if (this.loading) {
        return; // Already loading
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of patients
        const oldestLoadedPatient = this.patients[this.patients.length - 1];
        const { patients, isLast } = await coreApi.searchPatients(this.query, oldestLoadedPatient.id);

        // Push new chats to list
        this.doneLoadingPatients = isLast;
        for (let patient of patients) {
          this.patients.push(patient);
        }

      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    handleQueryInput() {
      // If theres a timeout, clear it
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.loadLatestPatients();
      }, QUERY_DELAY);
    },
    handleScroll(event) {
      if (this.doneLoadingPatients) {
        return; // Previous fetch returned 0 patients
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 0.9) {
        this.loadNextPatients();
      }
    },
    getPatientName(patient) {
      const names = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ]

      return names.filter(part => part).join(' ');
    },
    getPatientPicture(patient) {
      if (!patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${patient.profilePictureKey}`;
    },
    getPatientPhone(patient) {
      const phone = patient.mobilePhoneNumber;
      if (!phone) return 'No Phone Number'
      return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
    },
    getPatientEmail(patient) {
      const email = patient.emailAddress;
      if (!email) return 'No Email'
      return email;
    }
  },
  emits: ['onSelectPatient'],
  components: { PatientCreateModal }
}
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.patient-list {
  height: 1px;
  min-height: 1px;
  overflow: hidden;
  height: 400px;
  margin-top: 15px;
  overflow-y: auto;
}

.patient-list-item {
  padding: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.patient-list-item:hover {
  background: var(--color-fill-med);
}

.patient-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.patient-name {
  font-weight: bold;
}

.patient-picture {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: block;
}

.add-new-patient {
  width: 50px;
  height: 50px;
  padding: 15px;
  border: 2px dashed var(--color-border-med);
  border-radius: 25px;
}

@media screen and (max-width: 1000px) {
  .patient-picture {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .add-new-patient {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 5px;
  }
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}
</style>