import { postJson, postForm, getJson } from './common.js'

// Common

export async function fetchIndicators() {
  const resJson = await getJson('/messaging/common/indicators');
  if (!resJson.indicators) throw Error('Malformed response from backend');
  return resJson.indicators;
}

export async function downloadChatFile(fileId, b64 = false) {
  const res = await fetch(process.env.VUE_APP_API + '/messaging/common/download-chat-file', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId: fileId, b64 }),
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  if (b64) {
    const json = await res.json();
    return json; // also have json.fileName and json.mimeType
  }

  const blob = await res.blob();
  return blob;
}

// Patient Chats

export async function createPatientChat(patientId) {
  const resJson = await postJson('/messaging/patientchat/create', { patientId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listLatestPatientChats(afterChatId) {
  const resJson = await postJson('/messaging/patientchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchPatientChatInfo(chatId) {
  const resJson = await postJson('/messaging/patientchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function fetchPatientChatInfoForPatient(patientId) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-patient', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ patientId })
  });

  // If it's a 404, patient does not have chat
  if (res.status == 404) {
    return undefined;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function fetchPatientChatInfoForPrivateUrl(privateURL) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-url', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ privateURL })
  });

  // If it's a 401, patient is not authenticated right now
  if (res.status == 401) {
    return null;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function markPatientChatResolved(chatId, isResolved) {
  const resJson = await postJson('/messaging/patientchat/mark-resolved', { chatId, isResolved });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

// Patient Chat Messages

export async function patientChatListMessages(chatId, afterMessageId, fromMessageId) {
  const resJson = await postJson('/messaging/patientchatmessage/list', { chatId, afterMessageId, fromMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function patientChatSendUserText(patientId, text, senderMethod) {
  const resJson = await postJson('/messaging/patientchatmessage/send-user-text', { patientId, text, senderMethod });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendUserFile(patientId, file, senderMethod) {
  const formData = new FormData();
  formData.set('patientId', patientId);
  formData.set('senderMethod', senderMethod);
  formData.set('file', file);

  const resJson = await postForm('/messaging/patientchatmessage/send-user-file', formData);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientText(chatId, text) {
  const resJson = await postJson('/messaging/patientchatmessage/send-patient-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientFile(chatId, file) {
  const formData = new FormData();
  formData.set('chatId', chatId);
  formData.set('file', file);

  const resJson = await postForm('/messaging/patientchatmessage/send-patient-file', formData);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/patientchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatUnsendMessage(messageId) {
  await postJson('/messaging/patientchatmessage/unsend-message', { messageId });
}

// Unverfied chats

export async function listUnverifiedChats(afterChatId) {
  const resJson = await postJson('/messaging/unverifiedchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchUnverifiedChatInfo(chatId) {
  const resJson = await postJson('/messaging/unverifiedchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function markUnverifiedChatResolved(chatId, isResolved) {
  const resJson = await postJson('/messaging/unverifiedchat/mark-resolved', { chatId, isResolved });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function unverifiedChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

// Unverified chat messages

export async function unverifiedChatListMessages(chatId, afterMessageId, fromMessageId) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/list', { chatId, afterMessageId, fromMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function unverifiedChatSendUserText(chatId, text) {
  const resJson = await postJson('/messaging/unverifiedchatmessage/send-user-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}


// Group Chats

export async function createGroupChat(name, users) {
  const resJson = await postJson('/messaging/groupchat/create', { name, users });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listGroupChats(afterChatId) {
  const resJson = await postJson('/messaging/groupchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchGroupChatInfo(chatId) {
  const resJson = await postJson('/messaging/groupchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function markGroupChatAsRead(chatId) {
  await postJson('/messaging/groupchat/mark-read', { chatId });
}

// Group chat messages

export async function groupChatListMessages(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/groupchatmessage/list', { chatId, afterMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function groupChatSendUserText(chatId, text) {
  const resJson = await postJson('/messaging/groupchatmessage/send-user-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatSendUserFile(chatId, file) {
  const formData = new FormData();
  formData.set('chatId', chatId);
  formData.set('file', file);

  const resJson = await postForm('/messaging/groupchatmessage/send-user-file', formData);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatForwardMessage(groupChatId, refChatType, refMessageId) {
  const resJson = await postJson('/messaging/groupchatmessage/forward-message', { groupChatId, refChatType, refMessageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/groupchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}