<template>
  <div class="form-section questions-section" ref="section" :data-is-editing="isEditing">
    <div class="form-section-editor" v-if="isEditing">
      <input class="section-title" type="text" v-model="title" placeholder="Enter section title...">
      <textarea class="section-subtitle" ref="text" type="text" @input="resizeTextArea" v-model="subtitle" placeholder="Enter section subtitle..." />
      <table cellspacing="0" cellpadding="0">
        <tr>
          <th>Item Name</th>
        </tr>
        <tr ref="items" v-for="item of items" :key="item.id">
          <td><input type="text" v-model="item.label" @blur="onBlurItem"></td>
        </tr>
        <tr>
          <td><input type="text" placeholder="Add new item..." @keydown="appendNewRow"></td>
        </tr>
      </table>
    </div>
    <div class="form-section-content" v-else>
      <h2 class="section-title" v-if="section.title">{{ section.title }}</h2>
      <p class="section-subtitle" v-if="section.subtitle">{{ section.subtitle }}</p>
      <div class="checklist-items">
        <div class="checklist-item" v-for="item of section.items" :key="item.id">
          <CheckBox size="30px" thickness="3px" unfillColor="#f5f5f8" borderColor="#c2c2cc" :isChecked="false" />
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
    <transition mode="out-in" name="modal-fade">
      <div class="form-section-editor-overlay" v-if="isEditing"></div>
    </transition>
  </div>
</template>

<script>
import CheckBox from '@/components/shared/CheckBox.vue'

export default {
  name: 'CheckListSection',
  props: {
    section: Object,
    isEditing: Boolean
  },
  data() {
    return {
      title: this.section.title,
      subtitle: this.section.subtitle,
      items: structuredClone(this.section.items)
    }
  },
  watch: {
    isEditing: {
      handler: function (isNowEditing) {
        if (isNowEditing) {
          this.$nextTick(() => {
            this.resizeTextArea();
          })
        } else {
          this.onUpdateSection();
        }
      },
      immediate: true
    }
  },
  methods: {
    resizeTextArea() {
      const textAreaRef = this.$refs.text;
      if (textAreaRef) {
        textAreaRef.style.height = '0px';
        this.$nextTick(() => {
          textAreaRef.style.height = `${Math.max(30, textAreaRef.scrollHeight)}px`
        })
      }
    },
    appendNewRow() {
      this.items.push({
        id: Math.random(),
        label: ''
      })

      this.$nextTick(() => {
        const itemRefs = this.$refs.items;
        if (!itemRefs) return;
        const lastRef = itemRefs[itemRefs.length - 1];
        lastRef.querySelector('input').focus();
      })
    },
    onUpdateSection() {
      const newSection = structuredClone(this.section);
      newSection.items = this.items;
      newSection.title = this.title;
      newSection.subtitle = this.subtitle;
      console.info('Updating section', newSection);
      this.$emit('onUpdateSection', newSection);
    },
    onBlurItem() {
      this.items = this.items.filter(item => {
        return item.label.length > 0;
      })
    }
  },
  components: { CheckBox },
  emits: ['onUpdateSection']
}
</script>

<style scoped>
.form-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checklist-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
}

.checklist-item {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  gap: 10px;
}

h2 {
  margin-bottom: 32px;
}

th {
  text-align: left;
  padding: 10px;
}

tr:nth-child(odd) {
  background: var(--color-fill-med);
}

td input {
  border: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  background: transparent;
}

table {
  width: 100%;
}

.section-subtitle {
  display: block;
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 0px;
  line-height: 30px;
  margin-bottom: 30px;
}

input::placeholder {
  opacity: 0.5;
}

textarea {
  background: transparent;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
}

textarea::placeholder {
  opacity: 0.5;
}
</style>