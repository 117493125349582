<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/forms-inbox/', label: 'Submissions', hasNotif: false },
      { path: '/forms/', label: 'Account Forms', hasNotif: false },
      // { path: '/presets', label: 'Presets', hasNotif: false },
    ]" />
    <!-- Submission List -->
    <div class="panel-v2 submission-list-panel">
      <div class="panel-v2-header">
        <h2>Recent Submissions</h2>
        <button class="button small primary" @click="openModal('select-patient')">Send Forms</button>
      </div>
      <div class="panel-v2-body">
        <div class="search-bar">
          <img src="@/assets/icons/search.png" alt="Search Icon" class="search-bar-icon">
          <input type="text" class="form-control" placeholder="Search submissions..." autocomplete="off"
            data-lpignore="true" data-form-type="other" v-model="query" @input="handleQueryInput" ref="search" />
        </div>
        <div class="submission-list">
          <router-link :to="`/forms-inbox/${submission.id}`" class="submission-list-item"
            v-for="submission of submissions" :key="submission.id">
            <img src="/icons/patient.png" alt="Patient Picture" class="patient-picture">
            <div class="submission-description">
              <strong>{{ getPatientName(submission) }}</strong>
              <span class="submission-date">{{ getFormattedDate(submission) }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- Submission info -->
    <div class="panel-v2">

    </div>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <PatientSelectModal v-if="modal.id == 'select-patient'" @onSelectPatient="handleSelectPatient" />
            <SendFormsModal v-else-if="modal.id == 'send-forms'" :patient="modal.data.patient"
              @onSubmissionCreated="handleSubmissionCreated" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { store } from '../../store'
import * as formsApi from '../../api/forms'
import NavigationBar from '../../components/shared/NavigationBar'
import PatientSelectModal from '../../modals/shared/PatientSelectModal.vue';
import SendFormsModal from '../../modals/forms/SendFormsModal.vue';

export default {
  name: 'SubmissionsView',
  data() {
    return {
      store,
      modal: {
        open: false,
        id: '',
        data: {}
      },
      submissions: []
    }
  },
  created() {
    this.attemptFetchForms();
    this.attemptFetchSubmissions();
  },
  methods: {
    async attemptFetchForms() {
      try {
        const forms = await formsApi.listForms();
        store.accountForms = forms;
        console.info('Fetched forms from backend');
      } catch (err) {
        console.error(err);
      }
    },
    async attemptFetchSubmissions() {
      try {
        const submissions = await formsApi.listSubmissions();
        this.submissions = submissions;
        console.info('Fetched submissions from backend');
      } catch (err) {
        console.error(err);
      }
    },
    openModal(id, data) {
      console.info('opening modal', id);
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    getFormattedDate(submission) {
      const date = new Date(submission.dateCreated);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
    getPatientName(submission) {
      const patient = submission.patient;
      const name = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ].filter(part => part).join(' ');
      return name;
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleSelectPatient(patient) {
      this.openModal('send-forms', { patient })
    },
    handleSubmissionCreated(submission) {
      this.closeModal();

      this.submissions.unshift(submission);
    }
  },
  components: { NavigationBar, PatientSelectModal, SendFormsModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.submission-list-panel {
  flex-basis: 500px;
  flex-grow: 0;
}

.submission-list {
  margin-top: 30px;
  height: 1px;
  flex: 1;
  overflow-y: auto;
}

.submission-list-item {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px;
  align-items: center;
  color: unset;
  text-decoration: none;
  border-radius: 10px;
}

.submission-list-item.router-link-active {
  background: var(--color-fill-med);
}

.patient-picture {
  height: 40px;
  display: block;
  border-radius: 20px;
}

.submission-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.submission-date {
  color: #666;
}
</style>