<template>
  <div id="full" :data-chat-open="chatInfo != null">
    <!-- Navigation bar -->
    <NavigationBar :links="[
      { path: '/patient-chats', label: 'Patients', indicator: store.indicators.unreadPatientChats },
      { path: '/unverified-chats', label: 'Unverified', indicator: store.indicators.unreadUnverifiedChats },
      { path: '/group-chats', label: 'Group Chats', indicator: store.indicators.unreadGroupChats }
    ]" />

    <!-- Sidebar for chat list -->
    <ChatListSidebar :chats="getChatsToShow()" :isLoadingChats="isLoadingChats" :doneLoadingChats="doneLoadingChats"
      @onLoadMoreChats="loadNextChats" emptyText="Internal group chats will appear here.">
      <h2 class="heading">Recent Chats</h2>
      <button class="button small primary" @click="openModal('create-chat')">Start Chat</button>
    </ChatListSidebar>

    <!-- Conversation -->
    <transition mode="out-in" name="modal-appear">
      <ChatConversationPanel v-if="chatInfo" ref="panel" :messages="getMessagesToShow()"
        :isLoadingMessages="isLoadingMessages" :doneLoadingMessages="doneLoadingMessages">
        <template v-slot:header>
          <GroupChatConversationHeader :chatInfo="chatInfo" />
        </template>
        <template v-slot:footer>
          <ChatConversationFooter ref="panelFooter" :isSendingMessage="isSendingMessage">
            <!-- Send Button -->
            <template v-slot:right>
              <button class="button small primary send-button" @click.prevent="handleSendMessage">
                <img v-if="isSendingMessage" src="/loading-white.svg" alt="Loading Icon" class="loading">
                <span v-else>Send</span>
              </button>
            </template>
          </ChatConversationFooter>
        </template>

      </ChatConversationPanel>
    </transition>

    <!-- Modal -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <CreateGroupChatModel v-if="modal.id == 'create-chat'" @onChatCreated="handleChatCreated" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavigationBar from '../../components/shared/NavigationBar.vue';
import ChatListSidebar from '../../components/messaging/shared/ChatListSidebar.vue';
import ChatConversationPanel from '../../components/messaging/shared/ChatConversationPanel.vue';
import GroupChatConversationHeader from '../../components/messaging/officegroupchat/GroupChatConversationHeader.vue';
import ChatConversationFooter from '../../components/messaging/shared/ChatConversationFooter.vue'
import CreateGroupChatModel from '../../modals/messaging/groupchats/CreateGroupChatModal.vue'

import * as commonUtils from '../../utils/common'
import * as coreApi from '../../api/core'
import * as messagingApi from '../../api/messaging'
import * as socket from '../../socket'
import { store } from '../../store'

const FAKE_LATENCY = 100;

export default {
  name: 'GroupChatsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      store,

      chats: [],
      isLoadingChats: false,
      doneLoadingChats: false,

      chatInfo: null,
      messages: [],
      isLoadingMessages: false,
      doneLoadingMessages: false,

      isSendingMessage: false,
      cancelRequestsBefore: new Date()
    }
  },
  created() {
    Promise.all([
      this.loadLatestChats(),
      this.fetchIndicators(),
      this.fetchAccountInfo()
    ]).then(() => {
      console.info('Loaded data, connecting to socket');
      socket.connectToRoom('account', store.accountInfo.id);
    })
  },
  mounted() {
    socket.addEventListener('chat-event', this.handleChatUpdateEvent);
  },
  unmounted() {
    socket.removeEventListener('chat-event', this.handleChatUpdateEvent);
  },
  watch: {
    $route: {
      handler: function (to) {
        const chatId = to.params.id;
        this.chatInfo = null;
        if (chatId) {
          this.cancelRequestsBefore = new Date();
          this.loadChatInfo(chatId);
        }
      },
      immediate: true
    }
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleChatCreated(chat) {
      this.chats.unshift(chat);
      this.closeModal();
      this.$router.push(`/group-chats/${chat.id}`);
    },
    async fetchAccountInfo() {
      try {
        const accountInfo = await coreApi.fetchAccountInfo();
        store.accountInfo = accountInfo;
      } catch (err) {
        console.error(err.message);
      }
    },
    async fetchIndicators() {
      try {
        const indicators = await messagingApi.fetchIndicators();
        store.indicators = indicators;
        console.info('Loaded indicators', indicators);
      } catch (err) {
        console.error('Failed to fetch indicators', err);
      }
    },
    async loadLatestChats() {
      try {
        this.isLoadingChats = true;
        const { chats, isLast } = await messagingApi.listGroupChats();
        this.chats = chats;
        this.doneLoadingChats = isLast;
      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadNextChats() {
      if (this.isLoadingChats) {
        return; // Already loaded
      }

      try {
        this.isLoadingChats = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedChat = this.chats[this.chats.length - 1];
        const { chats, isLast } = await messagingApi.listGroupChats(oldestLoadedChat.id);

        // Push new chats to list
        this.doneLoadingChats = isLast;
        this.chats.push(...chats);

      } catch (err) {
        console.error('Failed to load latest chats');
      } finally {
        this.isLoadingChats = false;
      }
    },
    async loadChatInfo(chatId) {
      const requestStart = new Date();

      try {
        const chatInfo = await messagingApi.fetchGroupChatInfo(chatId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.chatInfo = chatInfo;
        await this.loadLatestMessages();
      } catch (err) {
        console.error('Failed to fetch chat info', err);
        alert('Failed to load chat: ' + err.message);
      } finally {
        const panelRef = this.$refs.panel;
        if (panelRef) {
          panelRef.scrollToBottom();
        }

        await this.markChatAsRead();
        await this.fetchIndicators();
      }
    },
    async loadLatestMessages() {
      const requestStart = new Date();

      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.messages = [];
      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.groupChatListMessages(chatId);
        if (requestStart < this.cancelRequestsBefore) return;
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadNextMessages() {
      const requestStart = new Date();

      if (this.isLoadingMessages) {
        return;
      }

      try {
        this.isLoadingMessages = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await messagingApi.groupChatListMessages(this.chatInfo.id, oldestLoadedMessage.id);
        if (requestStart < this.cancelRequestsBefore) return;

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async sendUserMessage(text, files) {
      try {
        this.isSendingMessage = true;

        // Send files
        let message;
        for (let file of files) {
          message = await messagingApi.groupChatSendUserFile(this.chatInfo.id, file);
          this.messages.push(message);
        }

        // Send message
        if (text) {
          message = await messagingApi.groupChatSendUserText(this.chatInfo.id, text);
          this.messages.push(message);
        }

        // Clear text
        const panelFooterRef = this.$refs.panelFooter;
        if (panelFooterRef) {
          panelFooterRef.clearTextArea();
        }

        // Scroll to bottom
        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (panelRef) {
            panelRef.scrollToBottom();
          }
        })

      } catch (err) {
        console.error('Failed to send message', err);
        alert('Failed to load messages: ' + err.message);
      } finally {
        this.isSendingMessage = false;
      }
    },
    async loadUpdatedChat(chatId) {
      if (!chatId) return;

      try {
        // Fetch chat from backend
        const chat = await messagingApi.fetchGroupChatInfo(chatId);

        // Update chat info if this is active chat
        if (this.chatInfo && this.chatInfo.id == chat.id) {
          this.chatInfo = chat;
        }

        // Insert in correct index
        const chats = this.chats.filter(c => c.id != chat.id);
        chats.push(chat);
        chats.sort((a, b) => new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated));
        this.chats = chats;
        console.info('Refreshed patient chat info', chatId);
      } catch (err) {
        console.error('Failed to fetch chat info', err);
      }
    },
    async loadUpdatedChatMessage(messageId) {
      if (!messageId) return;

      try {
        // Fetch message from backend
        const message = await messagingApi.groupChatMessageInfo(messageId);
        let indexToInsertAt = this.messages.findIndex(msg => msg.dateCreated > message.dateCreated);

        // Insert into correct index
        this.messages = this.messages.filter(msg => msg.id != message.id);
        if (indexToInsertAt == -1) {
          this.messages.push(message);
        } else {
          this.messages.splice(indexToInsertAt, 0, message);
        }

        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (!panelRef) return;
          panelRef.scrollToBottom();
        })

        console.info('Refreshed chat message info', messageId);
      } catch (err) {
        console.error('Failed to fetch chat message info', err);
      }
    },
    async markChatAsRead() {
      try {
        await messagingApi.markGroupChatAsRead(this.chatInfo.id);

        // Resolve active chat
        if (!this.chatInfo.isResolved) {
          store.indicators.unreadGroupChats--;
          this.chatInfo.isResolved = true;
        }

        // And resolve it in sidebar as well
        const chatInSidebar = this.chats.find(chat => chat.id == this.chatInfo.id);
        if (chatInSidebar) {
          chatInSidebar.isResolved = true;
        }

      } catch (err) {
        console.error(err);
      }
    },
    handleSendMessage() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return;

      // Send message
      const text = panelFooterRef.getText();
      const files = panelFooterRef.getFiles();
      this.sendUserMessage(text, files);
    },
    handleChatUpdateEvent(event) {
      const { chatType, chatId, messageId } = event;

      if (chatType == 'GroupChat') {
        // Reload chat info
        this.loadUpdatedChat(chatId);

        if (this.chatInfo && this.chatInfo.id == chatId) {
          // Reload chat message info
          this.loadUpdatedChatMessage(messageId);
        }
      }

      // Fetch indicators
      this.fetchIndicators().then(() => {
        // Mark current chat as read
        this.markChatAsRead();
      });
    },
    getChatsToShow() {
      const result = [];

      for (let chat of this.chats) {
        result.push({
          id: chat.id,
          link: `/group-chats/${chat.id}`,
          name: chat.name,
          time: commonUtils.formatDate(chat.dateLastUpdated),
          icons: chat.users.map(user => {
            return {
              src: commonUtils.getUserPicture(user),
              alt: user.name
            }
          }),
          isResolved: chat.isResolved
        })
      }

      return result;
    },
    getMessagesToShow() {
      const result = [];

      for (let message of this.messages) {
        result.push({
          id: message.id,
          dateCreated: message.dateCreated,
          isMySide: message.sender.id == store.userInfo.id,
          senderId: message.sender.id,
          senderName: message.sender.name,
          senderPicture: commonUtils.getUserPicture(message.sender),
          contentType: message.contentType,
          textContent: message.textContent,
          fileContent: message.fileContent,
          refContent: message.refContent,
          refChatType: message.refChatType,
          refChatId: message.refChatId
        })
      }

      return result;
    }
  },
  components: {
    NavigationBar, ChatListSidebar,
    GroupChatConversationHeader, ChatConversationPanel, ChatConversationFooter,
    CreateGroupChatModel
  }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.send-button {
  height: 44px;
}

@media screen and (max-width: 1000px) {
  #full[data-chat-open="true"] {
    padding: 0px;
  }

  #full[data-chat-open="true"]>nav {
    display: none;
  }
}
</style>