<template>
  <div class="form-section questions-section" ref="section" :data-is-editing="isEditing">
    <div class="form-section-editor" v-if="isEditing">
      <input class="section-title" type="text" v-model="title" placeholder="Enter section title...">
      <textarea class="section-subtitle" ref="text" type="text" @input="resizeTextArea" v-model="subtitle"
        placeholder="Enter section subtitle..." />
      <table cellspacing="0" cellpadding="0">
        <tr>
          <th>Chart Field</th>
          <th>Format</th>
          <th>Label</th>
          <th style="width: 60px">Req.</th>
        </tr>
        <tr ref="questions" v-for="question of questions" :key="question.id">
          <td><chart-field-select :value="question.path" @onSelectField="onSelectField(question, $event)" /></td>
          <td><select v-model="question.type" :disabled="isQuestionLinked(question)">
              <option value="ShortString">Short Response</option>
              <option value="LongString">Long Response</option>
              <option value="Date">Date</option>
              <option value="Email">Email</option>
              <option value="Dropdown">Dropdown</option>
              <option value="MultiSelect">Multi-Select</option>
              <option value="PhoneNumber">Phone Number</option>
              <option value="Struct">Structured</option>
              <option value="StructList">Structured List</option>
            </select></td>
          <td><input type="text" v-model="question.label" autocomplete="off" data-lpignore="true"
              data-form-type="other"></td>
          <td>
            <CheckBox size="20px" thickness="2px" @click.prevent.stop="question.isRequired = !question.isRequired"
              :isChecked="question.isRequired" />
          </td>
        </tr>
        <tr>
          <td><chart-field-select placeholder="Add new field" @onSelectField="addNewField" /></td>
        </tr>
      </table>
    </div>
    <div class="form-section-content" v-else>
      <h2 class="section-title" v-if="section.title">{{ section.title }}</h2>
      <p class="section-subtitle" v-if="section.subtitle">{{ section.subtitle }}</p>
      <div class="question-list">
        <div class="question" v-for="question of section.questions" :key="question.id">
          <label for="" class="question-label">
            {{ question.label }}
            <span class="optional-tag" v-if="!question.isRequired">(optional)</span></label>
          <input type="text" class="question-control" data-lpignore="true" autocomplete="off" data-form-type="other">
        </div>
      </div>
    </div>
    <transition mode="out-in" name="modal-fade">
      <div class="form-section-editor-overlay" v-if="isEditing"></div>
    </transition>
  </div>
</template>

<script>
import ChartFieldSelect from '../ChartFieldSelect.vue';
import CheckBox from '../../shared/CheckBox.vue';

export default {
  name: 'QuestionsSection',
  props: {
    section: Object,
    isEditing: Boolean
  },
  data() {
    return {
      title: this.section.title,
      subtitle: this.section.subtitle,
      questions: structuredClone(this.section.questions)
    }
  },
  watch: {
    isEditing: {
      handler: function (isNowEditing) {
        if (isNowEditing) {
          this.$nextTick(() => {
            this.resizeTextArea();
          })
        } else {
          this.onUpdateSection();
        }
      },
      immediate: true
    }
  },
  methods: {
    resizeTextArea() {
      const textAreaRef = this.$refs.text;
      if (textAreaRef) {
        textAreaRef.style.height = '0px';
        this.$nextTick(() => {
          textAreaRef.style.height = `${Math.max(30, textAreaRef.scrollHeight)}px`
        })
      }
    },
    addNewField(field) {
      this.questions.push({
        id: Math.random(),
        path: field.path,
        label: field.label,
        isRequired: true
      })
    },
    onUpdateSection() {
      const newSection = structuredClone(this.section);
      newSection.title = this.title;
      newSection.questions = this.questions;
      newSection.subtitle = this.subtitle;
      console.info('Updating section', newSection);
      this.$emit('onUpdateSection', newSection);
    },
    onSelectField(question, field) {
      question.path = field.path;
      question.label = field.label;
      question.type = field.type;
    },
    isQuestionLinked(question) {
      return question.path && !question.path.startsWith('custom');
    }
  },
  components: { ChartFieldSelect, CheckBox },
  emits: ['onUpdateSection']
}
</script>

<style scoped>
.form-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.question-list {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
}

.question {
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1;
  flex-basis: 300px;
}

.question-label {
  font-size: 16px;
  color: var(--color-txt-med);
  text-wrap: nowrap;
}

.optional-tag {
  opacity: 0.5;
  margin-left: 2px;
}

.question-control {
  width: 100%;
  padding: 10px;
  border: none;
  background: var(--color-fill-med);
  border-radius: 2px;
  font-size: 18px;
  border-bottom: 1px solid var(--color-border-dark);
}

.question-control:focus {
  outline: 1px solid var(--color-border-dark);
}

h2 {
  margin-bottom: 32px;
}

table {
  table-layout: fixed;
}

th {
  text-align: left;
  padding: 10px;
}

tr:nth-child(odd) {
  background: var(--color-fill-med);
}

td input,
td select {
  border: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  background: transparent;
}

td select {
  border: none;
  margin-left: -3px;
}

td>.container {
  margin-left: 10px;
  display: block;
}

table {
  width: 100%;
}

.section-subtitle {
  display: block;
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 0px;
  line-height: 30px;
  margin-bottom: 30px;
}

input::placeholder {
  opacity: 0.5;
}

textarea {
  background: transparent;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  resize: none;
  padding: 0;
}

textarea::placeholder {
  opacity: 0.5;
}

.link-icon {
  height: 16px;
  display: inline-block;
}
</style>