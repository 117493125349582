import { reactive } from 'vue'
import * as api from '@/api';

export const store = reactive({
  accountInfo: {},
  accountInfoLastPull: 0,
  indicators: {},

  accountUsers: [],
  accountInvitations: [],

  userInfo: {},
  userInfoLastPull: 0,
  // Forms
  accountForms: []
})

export const fetchAccountInfo = async (timeToIgnore = 5000) => {
  if (Date.now() < store.accountInfoLastPull + timeToIgnore) {
    console.debug('Did not fetch user info because we already have a fresh copy');
    return;
  }

  try {
    const account = await api.fetchAccountInfo();
    store.accountInfo = account;
    store.accountInfoLastPull = Date.now();
  } catch (err) {
    throw Error('Failed to fetch account info', err);
  }
}

export const fetchUserInfo = async (timeToIgnore = 5000) => {
  if (Date.now() < store.userInfoLastPull + timeToIgnore) {
    console.debug('Did not fetch user info because we already have a fresh copy');
    return;
  }

  try {
    const user = await api.userInfo();
    store.userInfo = user;
    store.userInfoLastPull = Date.now();
  } catch (err) {
    throw Error('Failed to fetch user info', err);
  }
}