<template>
  <div id="full">
    <ChatConversationPanel v-if="chatInfo" ref="panel" :messages="getMessagesToShow()"
      :isLoadingMessages="isLoadingMessages" :doneLoadingMessages="doneLoadingMessages"
      @onLoadMoreMessages="loadNextMessages">
      <!-- Conversation footer -->
      <template v-slot:footer>
        <ChatConversationFooter ref="panelFooter" :isSendingMessage="isSendingMessage">
          <template v-slot:right>
            <button class="button small primary send-button" @click.prevent="handleSendMessage">
              <img v-if="isSendingMessage" src="/loading-white.svg" alt="Loading Icon" class="loading">
              <span v-else>Send</span>
            </button>
          </template>
        </ChatConversationFooter>
      </template>
    </ChatConversationPanel>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <transition name="modal-pop" mode="out-in">
          <AuthPatientModal v-if="modal.id == 'auth-patient'" @patientLogin="handlePatientLogin" />
          <UploadFilesModal v-else-if="modal.id == 'upload-files'" @closeModal="closeModal" :chatInfo="chatInfo"
            @onFileUploaded="handleFileUploaded" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatConversationPanel from '../../components/messaging/shared/ChatConversationPanel.vue'
import ChatConversationFooter from '../../components/messaging/shared/ChatConversationFooter.vue'

import AuthPatientModal from '@/modals/shared/AuthPatientModal.vue'
import UploadFilesModal from '@/modals/messaging/UploadFilesModal.vue'
import * as messagingApi from '../../api/messaging'
import * as commonUtils from '../../utils/common'
import * as socket from '../../socket'

const FAKE_LATENCY = 200;

export default {
  name: 'PatientSecureChatView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,

      isSendinMessage: false,
      isLoadingMessages: false,
      doneLoadingMessages: false,
      messages: []
    }
  },
  mounted() {
    const params = this.$route.params;
    this.loadChatInfo(params.url)

    document.documentElement.style.overscrollBehavior = 'none';
    document.body.style.overscrollBehavior = 'none';

    socket.addEventListener('chat-event', this.handleChatUpdateEvent);
  },
  unmounted() {
    socket.removeEventListener('chat-event', this.handleChatUpdateEvent);
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {

      // Special case: auth modal
      if (this.modal.id == 'auth-patient') {
        return;
      }

      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    getMessagesToShow() {
      const result = [];

      for (let message of this.messages) {
        result.push({
          id: message.id,
          dateCreated: message.dateCreated,
          isMySide: message.senderType == 'Patient',
          senderId: message.sender.id,
          senderName: message.sender.name,
          senderPicture: commonUtils.getPatientPicture(message.sender),
          senderMethod: message.senderMethod,
          contentType: message.contentType,
          textContent: message.textContent,
          fileContent: message.fileContent
        })
      }

      return result;
    },
    handleSendMessage() {
      const panelFooterRef = this.$refs.panelFooter;
      if (!panelFooterRef) return;

      // Send message
      const text = panelFooterRef.getText();
      const files = panelFooterRef.getFiles();
      this.sendPatientMessage(text, files);
    },
    async handlePatientLogin() {
      const params = this.$route.params;
      await this.loadChatInfo(params.url);
      this.closeModal();
    },
    async loadChatInfo(privateUrl) {
      this.messages = [];

      try {
        const chatInfo = await messagingApi.fetchPatientChatInfoForPrivateUrl(privateUrl);

        if (!chatInfo) {
          console.info('Patient is not authenticated');
          this.openModal('auth-patient');
          return;
        }

        this.chatInfo = chatInfo;
        socket.connectToRoom('patient', this.chatInfo.patient.id);
        await this.loadLatestMessages();

        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (panelRef) {
            panelRef.scrollToBottom();
          }
        })

      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
        alert('Failed to fetch chat info');
      }
    },
    async loadLatestMessages() {
      if (!this.chatInfo.id) {
        this.doneLoadingMessages = true;
        return;
      }

      this.doneLoadingMessages = false;

      try {
        this.isLoadingMessages = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await messagingApi.patientChatListMessages(chatId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async loadNextMessages() {
      if (this.isLoadingMessages) {
        return;
      }

      try {
        this.isLoadingMessages = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await messagingApi.patientChatListMessages(this.chatInfo.id, oldestLoadedMessage.id);

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.isLoadingMessages = false;
      }
    },
    async sendPatientMessage(text, files) {
      try {
        this.isSendingMessage = true;

        // Send files
        let message;
        if (files) {
          for (let file of files) {
            message = await messagingApi.patientChatSendPatientFile(this.chatInfo.id, file);
            this.messages.push(message);
          }
        }

        // Send message
        if (text) {
          message = await messagingApi.patientChatSendPatientText(this.chatInfo.id, text);
          this.messages.push(message);
        }

        // Clear text
        const panelFooterRef = this.$refs.panelFooter;
        if (panelFooterRef) {
          panelFooterRef.clearTextArea();
        }

        // Scroll to bottom
        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (panelRef) {
            panelRef.scrollToBottom();
          }
        })

      } catch (err) {
        console.error('Failed to send message', err);
        alert('Failed to load messages: ' + err.message);
      } finally {
        this.isSendingMessage = false;
      }
    },
    async loadUpdatedChatMessage(messageId) {
      if (!messageId) return;

      try {
        // Fetch message from backend
        const message = await messagingApi.patientChatMessageInfo(messageId);
        let indexToInsertAt = this.messages.findIndex(msg => msg.dateCreated > message.dateCreated);

        // Insert into correct index
        this.messages = this.messages.filter(msg => msg.id != message.id);
        if (indexToInsertAt == -1) {
          this.messages.push(message);
        } else {
          this.messages.splice(indexToInsertAt, 0, message);
        }

        this.$nextTick(() => {
          const panelRef = this.$refs.panel;
          if (!panelRef) return;
          panelRef.scrollToBottom();
        })

        console.info('Refreshed chat message info', messageId);
      } catch (err) {
        console.error('Failed to fetch chat message info', err);
      }
    },
    handleChatUpdateEvent(event) {
      const { eventType, messageId } = event;

      if (eventType == 'UserMessage') {
        // Reload chat message info
        this.loadUpdatedChatMessage(messageId);
      }
    },
  },
  components: { AuthPatientModal, UploadFilesModal, ChatConversationPanel, ChatConversationFooter }
}
</script>

<style scoped>
#full {
  width: 100%;
  height: 100dvh;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
}

.modal-background {
  padding: 5px;
}

.send-button {
  height: 44px;
}
</style>