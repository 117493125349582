<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Update Patient Profile</h1>
    </div>
    <div class="modal-card-body">
      <form class="form" @submit.prevent="attemptUpdatePatient">
        <div class="form-group">
          <label class="form-label" for="first-name">First Name <span class="required">(required)</span></label>
          <input type="text" id="first-name" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="firstName">
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">Last Name <span class="required">(required)</span></label>
          <input type="text" id="last-name" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            v-model="lastName">
        </div>
        <div class="form-group">
          <label class="form-label" for="date-of-birth">Date of Birth <span class="required">(required)</span></label>
          <input type="text" id="date-of-birth" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="MM/DD/YYYY" v-model="dateOfBirth" @input="formatDateOfBirth">
        </div>
        <div class="form-group">
          <label class="form-label" for="phone-number">Phone Number</label>
          <input type="text" id="phone-number" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="(123) 123-1234" v-model="phoneNumber" @input="formatPhoneNumber">
        </div>
        <div class="form-group">
          <label class="form-label" for="email-address">Email Address</label>
          <input type="text" id="email-address" class="form-control" autocomplete="off" data-lpignore="true" data-form-type="other"
            placeholder="example@email.com" v-model="emailAddress">
        </div>
        <input type="submit" value="" style="display: none">
      </form>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click="attemptUpdatePatient">
        <img v-if="pendingUpdate" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Save Changes</span>
      </button>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as coreApi from '../../api/core.js'

export default {
  name: 'PatientCreateModal',
  props: {
    patient: Object,
  },
  data() {
    return {
      pendingUpdate: false,
      firstName: this.patient.firstName,
      lastName: this.patient.lastName,
      dateOfBirth: this.patient.dateOfBirth,
      phoneNumber: this.patient.mobilePhoneNumber || '',
      emailAddress: this.patient.emailAddress || '',
      error: ''
    };
  },
  mounted() {
    this.formatPhoneNumber();
  },
  methods: {
    async attemptUpdatePatient() {

      if (this.pendingCreate) return;

      if (this.firstName.length < 1) {
        this.error = 'First name is a required field';
        return;
      }

      if (this.lastName.length < 1) {
        this.error = 'Last name is a required field';
        return;
      }

      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(this.dateOfBirth)) {
        this.error = 'Date of birth is not valid';
        return;
      }

      const emailAddressCleaned = this.emailAddress.toLowerCase();
      if (this.emailAddress.length > 1 && !/^(.+)@(.+)\.(.+)$/.test(emailAddressCleaned)) {
        this.error = 'Email address must have format like example@gmail.com';
        return;
      }

      const phoneNumberCleaned = this.phoneNumber.replace(/[^0-9]/g, ''); 
      if (this.phoneNumber.length > 1 && phoneNumberCleaned.length != 10) {
        this.error = 'Phone number must have a format like (123) 123-1234';
        return;
      }

      try {
        this.error = '';
        this.pendingUpdate = true;

        const payload = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth,
          emailAddress: emailAddressCleaned,
          mobilePhoneNumber: phoneNumberCleaned
        }

        const patient = await coreApi.updatePatient(this.patient.id, payload);
        this.$emit('onUpdatePatient', patient)
      } catch (err) {
        this.error = err.message;
      } finally {
        this.pendingUpdate = false;
      }
    },
    formatDateOfBirth() {
      let v = this.dateOfBirth;
      v = v.replace(/\D/g, '').slice(0, 8);  // Remove non-digits and limit length
      if (v.length >= 5) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } else if (v.length >= 3) {
        this.dateOfBirth = `${v.slice(0, 2)}/${v.slice(2)}`;
      } else {
        this.dateOfBirth = v;
      }
    },
    formatPhoneNumber() {
      let v = this.phoneNumber;
      v = v.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 7) {
        this.phoneNumber = `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6, 10)}`;
      } else if (v.length >= 4) {
        this.phoneNumber = `(${v.substring(0, 3)}) ${v.substring(3, 6)}`
      } else if (v.length >= 1) {
        this.phoneNumber = `(${v.substring(0, 3)}`
      }
    },
  },
  emits: ['onUpdatePatient']
}
</script>

<style scoped>
.modal-card {
  width: 500px;
}

.required {
  opacity: 0.5;
}

</style>