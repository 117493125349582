<template>
  <div class="form-editor" ref="editor">
    <div class="form-header">
      <img :src="logoSrc" alt="Logo" class="form-logo" @click="changeLogo">
      <h1>{{ formName }}</h1>
    </div>
    <div class="form-section-container" v-for="(section, idx) of sections" :key="section.id">
      <!-- Insert Section Bar -->
      <InsertSectionBar @insertSection="insertSectionAbove(idx, $event)" />

      <!-- Section -->
      <TextSection v-if="section.type == 'INFO'" ref="sections" :section="section"
        :isEditing="isEditingSection(section)" @onUpdateSection="handleUpdateSection(idx, $event)" />
      <QuestionsSection v-else-if="section.type == 'QUESTIONS'" ref="sections" :section="section"
        :isEditing="isEditingSection(section)" @onUpdateSection="handleUpdateSection(idx, $event)" />
      <CheckListSection v-else-if="section.type == 'CHECKLIST'" ref="sections" :section="section"
        :isEditing="isEditingSection(section)" @onUpdateSection="handleUpdateSection(idx, $event)" />
      <SignatureSection v-else-if="section.type == 'SIGNATURE'" ref="sections" :section="section"
        :isEditing="isEditingSection(section)" @onUpdateSection="handleUpdateSection(idx, $event)" />

      <!-- Section Controls -->
      <EditSectionBar @onEditSection="handleEditSection(section)" @onDeleteSection="handleDeleteSection(section)"
        v-if="!editingSection" />
    </div>
    <div class="form-section-container" style="margin-top: 0">
      <InsertSectionBar @insertSection="appendSection($event)" />
    </div>
  </div>
</template>

<script>
import InsertSectionBar from './InsertSectionBar.vue'
import EditSectionBar from './EditSectionBar.vue';
import TextSection from './sections/TextSection.vue'
import QuestionsSection from './sections/QuestionsSection.vue';
import CheckListSection from './sections/CheckListSection.vue'
import SignatureSection from './sections/SignatureSection.vue';

export default {
  name: 'FormEditor',
  props: {
    formInfo: Object
  },
  data() {
    return {
      formName: 'Form',
      sections: [],
      logoSrc: '/logo-placeholder.png',
      editingSection: null
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.handleMouseClick);
    this.formName = this.formInfo.name;
    this.sections = structuredClone(this.formInfo.sections)

    if (this.formInfo.logoBase64) {
      this.logoSrc = this.formInfo.logoBase64;
    }
  },
  unmounted() {
    window.removeEventListener('mousedown', this.handleMouseClick);
  },
  methods: {
    createInitialSection(sectionType) {
      // Common properties
      const section = {
        type: sectionType,
        id: 's' + Math.random()
      }

      // Type-specific properties
      switch (sectionType) {
        case 'INFO':
          section.title = '';
          section.text = '';
          break;
        case 'QUESTIONS':
          section.title = '';
          section.subtitle = '';
          section.questions = [];
          break;
        case 'CHECKLIST':
          section.title = '';
          section.subtitle = '';
          section.items = [];
          break;
        case 'SIGNATURE':
          section.title = '';
          section.subtitle = '';
          break;
      }

      // Return
      return section;
    },
    appendSection(sectionType) {
      const newSection = this.createInitialSection(sectionType);
      this.sections.push(newSection);
      this.editingSection = newSection;
    },
    insertSectionAbove(idx, sectionType) {
      const newSection = this.createInitialSection(sectionType);
      this.sections.splice(idx, 0, newSection);
      this.editingSection = newSection;
    },
    isEditingSection(section) {
      if (!this.editingSection) {
        return false;
      }

      return section.id == this.editingSection.id;
    },
    handleMouseClick(event) {
      if (this.editingSection) {
        const target = event.target;
        const sectionEditors = document.querySelectorAll('.form-section-editor');
        for (let sectionEditor of sectionEditors) {
          if (sectionEditor.contains(target)) {
            return;
          }
        }

        this.editingSection = null;

        event.preventDefault();
        event.stopPropagation();
      }
    },
    handleEditSection(section) {
      this.editingSection = section;
    },
    handleUpdateSection(idx, newSection) {
      this.sections[idx] = newSection;
      this.$emit('onFormUpdated', this.sections);
    },
    handleDeleteSection(section) {
      this.$emit('onDeleteSection', section);
    },
    confirmDeleteSection(section) {
      this.sections = this.sections.filter(sect => {
        return sect.id != section.id;
      })
      this.$emit('onFormUpdated', this.sections);
    },
    getSections() {
      return this.sections;
    },
    getLogo() {
      if (this.logoSrc.startsWith('data:image/')) {
        return this.logoSrc;
      }

      return undefined;
    },
    changeLogo() {
      return new Promise((resolve, reject) => {
        // Create an input element to select the file
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*'; // Accept any image type

        input.onchange = (event) => {
          const file = event.target.files[0];
          if (!file) {
            reject(new Error('No file selected'));
            return;
          }

          const reader = new FileReader();
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              // Set the target height
              const targetHeight = 100;
              const aspectRatio = img.width / img.height;
              const targetWidth = targetHeight * aspectRatio;

              // Create a canvas to draw the resized image
              const canvas = document.createElement('canvas');
              canvas.width = targetWidth;
              canvas.height = targetHeight;

              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

              // Get the base64 string of the resized image
              const base64String = canvas.toDataURL('image/png');
              this.logoSrc = base64String;
            };
          };
          reader.readAsDataURL(file);
        };

        // Trigger the file input click event
        input.click();
      });
    }
  },
  components: { InsertSectionBar, EditSectionBar, TextSection, QuestionsSection, CheckListSection, SignatureSection },
  emits: ['onDeleteSection', 'onFormUpdated']
}
</script>

<style scoped>
.form-editor {
  width: 100%;
  max-width: 1200px;
  padding: 75px;
  background: #FFF;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 12px #00000022;
}

.form-section-container {
  position: relative;
  width: 100%;
}

.form-section {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
}

.form-header {
  margin-bottom: 30px;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(100px);
}

.center .button {
  width: auto;
  box-shadow: 0px 0px 12px #00000022;
}

.form-logo {
  height: 75px;
  display: block;
  margin-bottom: 30px;
  cursor: pointer;
}

</style>

<style>
.form-section-container .edit-section-bar {
  opacity: 0;
  transform: scale(0.9);
  transform-origin: center;
  transition: all ease-in-out 0.1s;
}

.form-section-container:hover .edit-section-bar {
  opacity: 1;
  transform: none;
}

.form-section-editor-overlay {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #131b28c8;
  pointer-events: none;
}

.form-section-editor {
  position: relative;
  padding: 30px;
  margin: -30px;
  z-index: 1002;
  border-radius: 5px;
  background: #FFF;
}

.form-section-content p {
  font-size: 18px;
  white-space: pre-wrap;
  line-height: 30px;
}

.form-section-content li {
  font-size: 18px;
  line-height: 30px;
}

.form-section-content a {
  color: rgb(0, 110, 255);
  word-break: break-all;
}
</style>