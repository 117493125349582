<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Confirm Patient Consent</h1>
      <p class="subtitle">Please confirm that you have the patient's consent to receive SMS messages, either verbally or in writing.</p>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="attemptOptInPatient">
        <img v-if="loading" src="/loading-white.svg" alt="Loading" class="loading">
        <span v-else>Confirm & Send</span>
      </button>
      <span class="error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>

import * as coreApi from '../../api/core'

export default {
  name: "SmsOptInModal",
  props: {
    patient: Object
  },
  data() {
    return {
      loading: false,
      error :''
    }
  },
  methods: {
    async attemptOptInPatient() {
      if (this.loading) {
        return;
      }

      try {
        this.error = '';
        this.loading = true;

        const patient = await coreApi.mobileOptInPatient(this.patient.id);
        this.$emit('onPatientOptedIn', patient);
        
      } catch (err) {
        console.error(err);
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  emits: ['onPatientOptedIn']
};
</script>

<style scoped>
.modal-card {
  max-width: 525px;
}

.modal-card-footer {
  margin-top: 0;
}

.consent-statement {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 45px;
}
</style>