<template>
  <div id="full">
    <NavigationBar :links="[
      { path: '/forms-inbox/', label: 'Submissions', hasNotif: false },
      { path: '/forms/', label: 'Account Forms', hasNotif: false },
      // { path: '/presets', label: 'Presets', hasNotif: false },
    ]" />
    <FormsListSidebar @openModal="openModal" />
    <transition name="modal-appear" mode="out-in">
      <FormEditorPanel v-if="formInfo" :formInfo="formInfo" @onUpdateForm="handleUpdateForm" />
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <CreateFormModal v-if="modal.id == 'create-form'" @onFormCreated="handleFormCreated" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { store } from '../../store'
import * as formsApi from '../../api/forms.js'
import NavigationBar from '../../components/shared/NavigationBar'
import FormsListSidebar from '../../components/forms/FormsListSidebar.vue'
import FormEditorPanel from '../../components/forms/FormEditorPanel.vue';
import CreateFormModal from '../../modals/forms/CreateFormModal.vue';

export default {
  name: 'FormsListView',
  data() {
    return {
      formInfo: null,
      store,
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        const formId = to.params.id;
        this.formInfo = null;
        if (formId) {
          this.attemptFetchFormInfo(formId);
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.attemptFetchForms();
  },
  methods: {
    openModal(id, data) {
      console.info('opening modal', id);
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleFormCreated() {
      this.closeModal();
    },
    async attemptFetchForms() {
      try {
        const forms = await formsApi.listForms();
        store.accountForms = forms;
        console.info('Fetched forms from backend');
      } catch (err) {
        console.error(err);
      }
    },
    async attemptFetchFormInfo(id) {
      try {
        const formInfo = await formsApi.fetchFormInfo(id);
        this.formInfo = formInfo;
      } catch (err) {
        console.error(err);
      }
    },
    async handleUpdateForm(sections, logoBase64) {
      try {
        const id = this.formInfo.id;
        await formsApi.updateForm(id, sections, logoBase64);
        console.info('Saved form successfully', sections);
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: { NavigationBar, FormsListSidebar, FormEditorPanel, CreateFormModal }
}
</script>

<style scoped>
#full {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>